<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <registration-cover />
      </v-col>

      <v-col col="6" class="form-col">
        <div class="form-col2-container">
          <form novalidate @submit.prevent="signIn" class="top-section">
            <div class="title">Welcome!</div>
            <div class="subtitle" style="margin-top: 0px">
              Log in to SeamlessSource:
            </div>
            <div class="center">
              <div class="md-layout email-field-width">
                <div class="md-layout-item item-size-100">
                  <md-field
                    :class="getValidationClass('email')"
                    class="email-field"
                    id="email"
                  >
                    <div class="email-input">
                      <input
                        class="input-width"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        v-focus
                        autocomplete="email"
                        v-model="model.email"
                        :disabled="signingIn"
                      />
                    </div>
                    <span class="md-error" v-if="emailValidationError">{{
                      emailValidationError
                    }}</span>
                  </md-field>
                </div>
              </div>
              <div class="md-layout email-field-width">
                <div class="md-layout-item item-size-100">
                  <md-field
                    :class="getValidationClass('password')"
                    class="email-field"
                    id="password"
                  >
                    <div class="email-input" style="position: relative">
                      <input
                        class="input-width"
                        style="padding-right: 35px"
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        id="password"
                        placeholder="Password"
                        v-model="model.password"
                        :disabled="signingIn"
                      />
                      <div
                        class="password-button"
                        @click="togglePasswordVisibility"
                      >
                        <v-icon>{{
                          showPassword ? "mdi-eye-off" : "mdi-eye"
                        }}</v-icon>
                      </div>
                    </div>
                    <span class="md-error" v-if="passwordValidationError">{{
                      passwordValidationError
                    }}</span>
                  </md-field>
                </div>
              </div>
              <div class="md-layout email-field-width">
                <div
                  v-if="verified"
                  class="forgot-password"
                  @click="showDialog = true"
                >
                  Forgot Password?
                </div>

                <div v-if="!verified" class="forgot-password">
                  <a
                    class="forgot-password-a"
                    :href="'#'"
                    @click.prevent="sendEmailVerificationLink"
                    >Your account needs to verify, click here</a
                  >
                </div>
              </div>
              <md-button
                type="submit"
                class="wide-button login-button"
                :disabled="saving"
                id="register"
              >
                <div class="signup-text">Log in</div>
              </md-button>

              <md-button
                v-if="ssoEnabled"
                class="margin-s wide-button sign-with-google"
                @click.prevent="authProvider('google')"
                ><img src="../../assets/google_icon.png" width="20px" /> Log in
                WITH GOOGLE
              </md-button>
              <div class="md-layout bottom-section">
                <div class="md-layout-item md-size-100">
                  <div class="new-user-section">
                    <div class="login">
                      New User?
                      <a href="#/register" class="login-link">Sign Up</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </v-col>
    </v-row>
    <md-dialog :md-active.sync="showDialog">
      <div class="form-container">
        <user-forgot-password @close="closeWindow" />
      </div>
    </md-dialog>
  </v-container>
</template>

<script>
import validationMixin from "../../validation/validation_mixin";
import { required, email } from "vuelidate/lib/validators";
import auth from "../../auth";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import validation from "../../validation";
import restAdapter from "../../restAdapter";
import RegistrationCover from "../../components/RegistrationCover.vue";
import { StatusCodes } from "http-status-codes";
import UserForgotPassword from "../user/ForgotPassword.vue";

export default {
  name: "userLogin",
  components: {
    UserForgotPassword,
    RegistrationCover,
  },
  mixins: [validationMixin],
  data() {
    return {
      logoImg: require(`../../assets/seamless-source-banner.png`),
      model: {
        email: null,
        password: null,
      },
      signingIn: false,
      verified: true,
      isSubmitting: false,
      showDialog: false,
      showPassword: false,
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  validations: {
    model: {
      email: {
        required,
        email: (val) => email(validation.emailFormatter(val)),
      },
      password: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.query.status && this.$route.query.status === "verified") {
      notification.success(
        "Your email has been verified successfully. Please login"
      );
    }
  },

  computed: {
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }
      if (!this.$v.model.email.email) {
        return "Invalid email";
      }
      return null;
    },
    passwordValidationError() {
      if (!this.$v.model.password.required) {
        return "The password is required";
      }
      return null;
    },
    ssoEnabled() {
      return conf.sso === true;
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.model.email = null;
      this.model.password = null;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    signIn() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.signingIn = true;
      const credentials = {
        email: this.model.email,
        password: this.model.password,
      };
      auth
        .login(credentials)
        .then((response) => {
          this.signingIn = false;
          this.$router.push(this.$route.query.redirect || "/app");
        })
        .catch((error) => {
          this.signingIn = false;
          const attemptsLeft = error.response.data.remaining_invalid_attempts;
          if (
            error.response &&
            (error.response.status === StatusCodes.BAD_REQUEST ||
              error.response.status === StatusCodes.UNAUTHORIZED)
          ) {
            const errors = {
              emailPassword: ["Invalid email or password"],
            };
            if (attemptsLeft >= 0) {
              errors.loginLimit = [
                `You have ${attemptsLeft} login ${
                  attemptsLeft === 1 ? "attempt" : "attempts"
                } remaining`,
              ];
            }
            notification.errors(errors);
          } else if (error.response.status === StatusCodes.NOT_ACCEPTABLE) {
            auth.user.isRememberToken = true;
            auth.user.rememberToken = error.response.data.token;
            auth.user.token = null;
            auth.user.email = this.model.email;
            auth.setAuth(auth.user);

            notification.errors({
              emailPassword: [
                "Email Not Verified! Please check your inbox or resend the verification email",
              ],
            });
            this.verified = false;
          } else if (error.response.status === StatusCodes.TOO_MANY_REQUESTS) {
            notification.errors({
              loginLimit: [
                "Your account has been temporarily disabled. Please try again after 1 hour",
              ],
            });
          } else {
            notification.error("Something went wrong, please try again later");
          }
        });
    },
    authProvider(provider) {
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.socialLogin(provider, response);
        })
        .catch((err) => {
          if (err.error) {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    socialLogin(provider, response) {
      auth
        .extractSocialDetails(provider, response)
        .then((response) => {
          if (response.data.already_registered) {
            this.signingIn = false;
            this.$router.push(this.$route.query.redirect || "/app");
            window.location.reload(true);
          } else {
            this.$router.push({ path: "/complete_profile" });
          }
        })
        .catch((err) => {
          notification.error("Something went wrong, please try again later");
        });
    },
    sendEmailVerificationLink() {
      this.isSubmitting = true;
      restAdapter
        .post("/api/email/resend", { email: this.model.email })
        .then((response) => {
          this.isSubmitting = false;
          notification.success(
            "Verification email has been sent to " +
              this.model.email +
              ". " +
              "Please check your inbox"
          );
          auth.user.isRememberToken = false;
          auth.user.rememberToken = null;
          auth.user.token = null;
          auth.setAuth(auth.user);
        })
        .catch((error) => {
          this.isSubmitting = false;

          if (
            error.response &&
            (error.response.status === StatusCodes.BAD_REQUEST ||
              error.response.status === StatusCodes.UNAUTHORIZED ||
              error.response.status === StatusCodes.NOT_FOUND)
          ) {
            notification.errors({ emailPassword: ["Invalid email"] });
          } else {
            notification.error("Something went wrong, please try again later");
          }
        });
    },

    closeWindow() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/Login.scss";
</style>
