<template>
  <div>
    <form
      novalidate
      class="forgot-password-form md-layout"
      @submit.prevent="sendResetPasswordLink"
    >
      <md-card class="external-container">
        <div class="container">
          <md-card-header class="title-container">
            <div v-if="!verified" class="md-title title-color">
              Verify Email?
            </div>
            <div v-else class="md-title title-color">Forgot Password?</div>
          </md-card-header>

          <md-card-content class="email-container">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('email')">
                <label for="email">Email</label>
                <md-input
                  type="email"
                  name="email"
                  id="email"
                  autocomplete="email"
                  v-model="model.email"
                  @input="inputEmail"
                  :disabled="isSubmitting"
                />
                <span class="md-error" v-if="emailValidationError">{{
                  emailValidationError
                }}</span>
              </md-field>
            </div>
          </md-card-content>

          <div
            v-if="!verified"
            :class="!model.email ? 'verify_email_msg' : 'verify_email_message'"
          >
            Your account needs to verify
          </div>

          <div v-if="addRecaptcha">
            <md-card-actions style="justify-content: center">
              <vue-recaptcha
                :sitekey="conf.captchaKey"
                ref="recaptcha"
                :loadRecaptchaScript="true"
                @verify="onCaptchaVerified"
                @expired="onExpired"
              >
              </vue-recaptcha>
            </md-card-actions>
            <div
              style="padding-bottom: 15px; text-align: center"
              class="md-error"
              v-if="recaptchaError"
            >
              {{ recaptchaError }}
            </div>
          </div>

          <md-card-actions class="md-layout btn-container">
            <md-button
              id="goBack"
              class="cancel-btn"
              :disabled="isSubmitting"
              @click="closeWindow"
              >Cancel</md-button
            >
            <md-button
              v-if="!verified"
              @click.prevent="sendEmailVerificationLink"
              id="sendVerifytLink"
              type="submit"
              class="verify-button"
              :disabled="isSubmitting"
              >Send Verify Link</md-button
            >
            <md-button
              v-else
              id="sendResetLink"
              type="submit"
              class="submit-button"
              :disabled="isSubmitting"
              >Send Reset Link</md-button
            >
          </md-card-actions>
        </div>
      </md-card>
    </form>
  </div>
</template>

<script>
import validationMixin from "../../validation/validation_mixin";
import { required, email } from "vuelidate/lib/validators";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import * as conf from "../../conf.yml";
import VueRecaptcha from "vue-recaptcha";
import auth from "../../auth";

export default {
  name: "ForgotPassword",
  mixins: [validationMixin],
  components: { VueRecaptcha },

  data() {
    return {
      model: {
        email: null,
        password: null,
      },
      conf: conf,
      recaptcha: null,
      recaptchaError: "",
      addRecaptcha: false,
      isSubmitting: false,
      verified: true,
    };
  },
  validations: {
    model: {
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }

      if (!this.$v.model.email.email) {
        return "Invalid email";
      }

      return null;
    },
  },

  mounted() {
    this.addRecaptcha = conf.addCaptcha;
  },

  methods: {
    clearForm() {
      this.$v.$reset();
      this.model.email = null;
      this.model.password = null;
      if (this.addRecaptcha) {
        this.recaptcha = null;
        this.$refs.recaptcha.reset();
      }
    },
    sendResetPasswordLink() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (!this.recaptcha && this.addRecaptcha) {
        this.recaptchaError = "Please select the captcha";
        return;
      }

      this.isSubmitting = true;

      const credentials = {
        email: this.model.email,
        recaptcha: this.recaptcha,
      };

      restAdapter
        .post("/api/forgot_password", credentials)
        .then((response) => {
          if (response.data.notVerified) {
            this.verified = false;
          } else {
            notification.success(
              "A password reset link has been sent to " + this.model.email
            );
            this.clearForm();
            this.closeWindow();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            (error.response.status == 400 || error.response.status == 401)
          ) {
            notification.errors({ emailPassword: ["Invalid email."] });
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    sendEmailVerificationLink() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isSubmitting = true;
      restAdapter
        .post("/api/email/resend", { email: this.model.email })
        .then((response) => {
          notification.success(
            "Verification email has been sent to " +
              this.model.email +
              ". " +
              "Please check your inbox"
          );
          auth.user.isRememberToken = false;
          auth.user.rememberToken = null;
          auth.user.token = null;
          auth.setAuth(auth.user);
          this.closeWindow();
        })
        .catch((error) => {
          if (
            error.response &&
            (error.response.status == 400 || error.response.status == 401)
          ) {
            notification.errors({ emailPassword: ["Invalid email."] });
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onCaptchaVerified(response) {
      this.recaptcha = response;
      this.recaptchaError = "";
    },
    onExpired() {
      this.model.recaptcha = null;
      this.recaptchaError = "";
    },

    closeWindow() {
      this.$emit("close");
    },

    inputEmail() {
      this.verified = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/ForgotPassword.scss";
</style>
