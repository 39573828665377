<template>
  <v-container>
    <div class="left-side-container">
      <div class="seamlesssource-banner">
        <a href="#/">
          <img :src="logoImg" max-width="150px" />
        </a>
      </div>
      <div class="subtitle">Make your collection with our</div>
      <div class="seamlesssource-title">Seamless Sourcing Platform</div>
    </div>
    <background-image />
  </v-container>
</template>

<script>
import BackgroundImage from "../assets/background-images/BackgroundImage.vue";

export default {
  name: "RegistrationCover",
  components: { BackgroundImage },
  data: () => ({
    logoImg: require(`../assets/seamless-source-banner.png`),
  }),
};
</script>

<style scoped>
.left-side-container {
  position: absolute;
  margin: 50px 50px;
  width: 300px;
}

.subtitle {
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #442d65;
  text-align: left;
}

.seamlesssource-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #442d65;
}
.seamlesssource-banner {
  max-width: 60%;
}
</style>
